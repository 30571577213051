<template>
  <div>
    <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增门店信息' : '修改门店信息'"
      :close-on-click-modal="false" @closed="cancel" width="720px">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="86px" label-suffix=":"
        v-if="form" :disabled="disabled">
        <div class="h sb" v-if="$erp.enable()">
          <el-form-item label="ERP账套" prop="erpAccountId">
            <quick-select v-model="form.erpAccountId" value-field="id" filterable url="api/shop/erp/account"
              placeholder="选择ERP账套" style="width: 240px;" />
          </el-form-item>
          <el-form-item prop="erpCode" label="ERP编码">
            <el-input v-model="form.erpCode" :maxlength="40" @keypress.enter.native="handleQueryByERP"
              @change="handleQueryByERP" :readonly="searching" style="width: 240px;">
              <el-button slot="append" icon="el-icon-search" v-loading="searching" :disabled="!form.erpCode"
                @click="handleQueryByERP"></el-button>
            </el-input>
          </el-form-item>
        </div>
        <el-form-item label="门店名称" prop="shopName">
          <el-input v-model="form.shopName" :maxlength="100" />
        </el-form-item>

        <div class="h">
          <el-form-item label="所在地区" prop="provinceCode">
            <quick-select v-model="form.provinceCode" :label.sync="form.provinceName" url="api/regionalDict/province"
              value-field="code" placeholder="选择省份" filterable style="width: 192px;"
              @change="form.cityCode = null; form.districtCode = null;" />
          </el-form-item>
          <el-form-item label-width="10px" prop="cityCode" style="width: 202px;">
            <quick-select v-model="form.cityCode" :label.sync="form.cityName"
              :url="`api/regionalDict/parent/${form.provinceCode}`" value-field="code" placeholder="选择城市" filterable
              v-if="form.provinceCode" style="width: 100%;" @change="form.districtCode = null" />
            <el-input disabled placeholder="请先选择省份" v-else />
          </el-form-item>
          <el-form-item label-width="10px" prop="districtCode" style="width: 202px;">
            <quick-select v-model="form.districtCode" :label.sync="form.districtName"
              :url="`api/regionalDict/parent/${form.cityCode}`" value-field="code" placeholder="选择地区" filterable
              v-if="form.cityCode" style="width: 100%;" />
            <el-input disabled placeholder="请先选择城市" v-else />
          </el-form-item>
        </div>

        <el-form-item label="详细地址" prop="address">
          <el-input v-model="form.address" maxlength="100" />
        </el-form-item>

        <div class="h sb">
          <el-form-item label="所属区域" prop="areaId">
            <tree-picker v-model="form.areaId" url="api/area" flat placeholder="请选择所属区域" filterable
              style="width: 240px;" />
          </el-form-item>
          <el-form-item label="门店库房" prop="warehouseId">
            <quick-select v-model="form.warehouseId" filterable url="api/warehouse" placeholder="选择门店库房"
              style="width: 240px;" clearable />
          </el-form-item>
        </div>
        <div class="h sb">
          <el-form-item label="分公司" prop="company">
            <el-input v-model="form.company" maxlength="50" style="width:240px" />
          </el-form-item>
          <el-form-item label="税号" prop="taxNo">
            <el-input v-model="form.taxNo" maxlength="50" style="width:240px" />
          </el-form-item>
        </div>
        <div class="h sb">
          <el-form-item label="开店日期" prop="openDate">
            <el-date-picker v-model="form.openDate" value-format="timestamp" style="width: 240px" />
          </el-form-item>
          <el-form-item label="撤店日期" prop="closeDate">
            <el-date-picker v-model="form.closeDate" value-format="timestamp" style="width: 240px" />
          </el-form-item>
        </div>
        <div class="h sb">
          <el-form-item label="店长" prop="shopOwner">
            <el-input v-model="form.shopOwner" maxlength="50" style="width:240px" />
          </el-form-item>
          <el-form-item label="商场" prop="market">
            <el-input v-model="form.market" maxlength="50" style="width:240px" />
          </el-form-item>
        </div>
        <div class="h sb">
          <el-form-item label="建筑面积" prop="buildArea">
            <el-input v-model="form.buildArea" maxlength="50" style="width:240px" />
          </el-form-item>
          <el-form-item label="实用面积" prop="actualArea">
            <el-input v-model="form.actualArea" maxlength="50" style="width:240px" />
          </el-form-item>
        </div>
        <div class="h sb">
          <el-form-item label="联系电话" prop="telephone">
            <el-input v-model="form.telephone" maxlength="50" style="width:240px" />
          </el-form-item>
          <el-form-item label="所属物业" prop="propertyName">
            <quick-select v-model="form.propertyName" filterable url="api/dictDetail?dictName=property_name"
              value-field="label" display-field="label" placeholder="请选择" style="width: 240px;" />
          </el-form-item>
        </div>
        <div class="h sb">
          <el-form-item label="门店品牌" prop="brand">
            <quick-select v-model="form.brand" :label.sync="form.brandName" multiple filterable url="api/brand"
              placeholder="请选择" style="width:240px" @change="form.series = null" />
          </el-form-item>
          <el-form-item label="门店系列" prop="series">
            <quick-select v-model="form.series" filterable url="api/shoSeries/list" :params="{ brand: form.brand }"
              value-field="name" placeholder="请选择" style="width:240px" multiple />
          </el-form-item>
        </div>
        <div class="h sb">
          <el-form-item label="启用" prop="enabled">
            <el-switch v-model="form.enabled" active-color="#13ce66" />
          </el-form-item>
          <el-form-item label="门店状态" prop="statusInfo">
            <el-input v-model="form.statusInfo" maxlength="50" style="width:240px" />
          </el-form-item>
        </div>

        <el-form-item label="备注信息" prop="info">
          <el-input type="textarea" v-model="form.info" :rows="2" resize="none" maxlength="250" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { add, edit, getByErpCode } from "@/api/shopInformation";
import { getStatementType } from "@/api/capitalPool";

export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    getStatementType().then((res) => {
      this.entType = res;
    });
  },
  data() {
    let checkHasErpIdError = (rule, val, callback) => {
      if (this.errors.erpCode) {
        callback("未找到指定的信息");
      } else {
        callback();
      }
    };
    return {
      loading: false,
      searching: false,
      dialog: false,
      disabled: false,
      entType: null,
      brandId: null,
      errors: {
        erpCode: false,
      },
      form: null,
      rules: {
        shopName: [
          { required: "true", message: "请输入门店名称", trigger: "blur" },
        ],
        erpCode: [
          { required: true, message: "请输入ERP编码", trigger: "blur" },
          { validator: checkHasErpIdError },
        ],
        provinceCode: [{ required: true, message: "请选择省份" }],
        cityCode: [{ required: true, message: "请选择城市" }],
        districtCode: [{ required: true, message: "请选择地区" }],
        erpAccountId: [{ required: true, message: "请选择ERP账套" }],
        erpDeptCode: [{ required: true, message: "请输入ERP部门编码" }],
        address: [{ required: true, message: "请输入详细地址" }],
        openDate: [{ required: true, message: "请选择开店日期" }],
        market: [{ required: true, message: "请输入商场" }],
        actualArea: [{ required: true, message: "请输入实用面积" }],
        buildArea: [{ required: true, message: "请输入建筑面积" }],
        propertyName: [{ required: true, message: "请输入所属物业" }],
        brand: [{ required: true, message: "请选择门店品牌" }],
        series: [{ required: true, message: "请选择门店系列" }],
      },
    };
  },
  methods: {
    cancel() {
      this.form = null;
    },

    doSave() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.form));
          data.series = data.series.join(",");
          data.brand = data.brand.join(",");
          data.brandName = data.brandName.join(",");

          this.loading = true;
          if (this.isAdd) {
            this.doAdd(data);
          } else this.doEdit(data);
        }
      });
    },
    doAdd(data) {
      add(data)
        .then((res) => {
          this.dialog = false;
          this.$notify({
            title: "保存成功",
            type: "success",
            duration: 2500,
          });
          this.loading = false;
          this.$parent.init();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    doEdit(data) {
      edit(data)
        .then((res) => {
          this.dialog = false;
          this.$notify({
            title: "修改成功",
            type: "success",
            duration: 2500,
          });
          this.loading = false;
          this.$parent.init();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    restForm(form) {
      this.dialog = true;
      this.disabled = false;
      if (form && form.series) {
        form.series = form.series.split(",");
      }
      if (form && form.brandName) {
        form.brandName = form.brandName.split(",");
      }
      if (form && form.brand) {
        form.brand = form.brand.split(",");
        form.brand = form.brand.map(Number);
      }
      this.form = form || {
        erpAccountId: null,
        shopName: null,
        info: null,
        provinceCode: null,
        provinceName: "",
        cityCode: null,
        cityName: "",
        districtCode: null,
        districtName: "",
        address: null,
        areaId: null,
        warehouseId: null,
        erpCode: null,
        company: "",
        taxNo: "",
        openDate: null,
        closeDate: null,
        shopOwner: "",
        market: "",
        buildArea: "",
        actualArea: "",
        enabled: true,
        statusInfo: "",
        series: null,
        brand: null,
      };
    },
    handleQueryByERP() {
      if (this.form.erpCode) {
        this.searching = true;
        this.form.shopName = "";
        this.errors.erpCode = false;
        getByErpCode(this.form.erpCode)
          .then((res) => {
            this.form.shopName = res.alias;
            this.errors.erpCode = false;
          })
          .catch((e) => {
            this.errors.erpCode = true;
          })
          .finally((_) => {
            this.searching = false;
            his.$refs.form.validateField("erpCode");
          });
      }
    },
  },
};
</script>

